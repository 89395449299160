<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			name: 'analisis_riesgo_del_negocio',
			form: {
				descripcionActividad: '',
				multipleGeografia: '',
				comentariosMultipleActividadEconomica: '',
				multipleActividadEconomica: '',
				vulnerabilidadTipoCambio: '',
				comentariosVulnerabilidadTipoCambio: '',
				hechoRelevante: '',
				comentariosHechoRelevante: '',


			},
			geografiasForm: [
				{
					nombre: '',
					query: '',
					ano: '',
					porcentaje: ''
				}
			],
			geografias: [],
			actividadesForm: [
				{
					nombre: '',
					query: '',
					ano: '',
					porcentaje: ''
				}
			],
			actividades: [],
			principalesClientesForm: [
				{
					nombre: '',
					ano: '',
					participacion: ''
				}
			],
			principalesClientes: [],

			chart: {
				series: [44, 55, 41],
				chartOptions: {
					labels: ['Mexico', 'España', 'Panama'],
					colors: ['#1973B8', '#D8BE75', '#2DCCCD'],
					chart: {
						type: 'donut',
					},
					responsive: [{
						options: {
							pie: {
								donut: {
									size: '20%'
								}
							},
							chart: {
								width: 120
							},
							legend: {
								position: 'bottom'
							}
						}
					}]
				},
			}
		}
	},

	computed: {
		showGeo() {
			let data = this.geografias.filter(sh => {
				return sh.nombre && sh.porcentaje
			})
			return data
		},

		showActivities() {
			let data = this.actividades.filter(sh => {
				return sh.nombre && sh.porcentaje
			})
			return data
		},
		showPrincipalesClientes() {
			let data = this.principalesClientes.filter(sh => {
				return sh.nombre && sh.participacion
			})
			return data
		}
	},

	watch: {
		// 'client.shareholders': {
		// 	handler(nVal) {
		// 		if(nVal.length) {
		// 			this.$set(this.shareholders, [...nVal])
		// 		}
		// 	}
		// }
		'client': {
			handler(nVal) {
				this.fillForm()
			},
			deep: true,
			immediate: true
		},
	},
	methods: {
		calcFill() {
			let fields = Object.keys(this.form)
			
			if(this.form.multipleActividadEconomica == 'NO') {
				this.form.comentariosMultipleActividadEconomica  = ''
				let index = fields.indexOf('comentariosMultipleActividadEconomica')
				fields.splice(index, 1)
			}

			if(this.form.vulnerabilidadTipoCambio == 'NO') {
				this.form.comentariosVulnerabilidadTipoCambio = ''
				let index = fields.indexOf('comentariosVulnerabilidadTipoCambio')
				fields.splice(index, 1)
			}

			if(this.form.hechoRelevante == 'NO') {
				this.form.comentariosHechoRelevante = ''
				let index = fields.indexOf('comentariosHechoRelevante')
				fields.splice(index, 1)
			}

			let points = 100 / fields.length

			let fill = 0

			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},

		fillForm() {
			let data = {}
			if(this.client.arce.hasOwnProperty(this.name)) {
				data = {...this.client.arce[this.name]}
			}
			if(!Object.keys(data).length) {
				this.$set(this, 'form', {
					descripcionActividad: '',
					multipleGeografia: '',
					comentariosMultipleActividadEconomica: '',
					multipleActividadEconomica: '',
					vulnerabilidadTipoCambio: '',
					comentariosVulnerabilidadTipoCambio: '',
					hechoRelevante: '',
					comentariosHechoRelevante: '',
				})
				this.$set(this, 'geografias', [
					{
						nombre: '',
						query: '',
						ano: '',
						porcentaje: ''
					}
				])
				this.$set(this, 'actividades', [
					{
						nombre: '',
						query: '',
						ano: '',
						porcentaje: ''
					}
				])
				return 
			}
			this.$set(this, 'geografias', [...data.geografias])
			this.$set(this, 'actividades', [...data.actividades])
			delete data['geografias']
			delete data['actividades']
			this.$set(this, 'form', data)
		},
		save() {
			let vm = this
			let group = {...this.client}
			group.arceFill[this.name] = this.calcFill()
			group.arce[this.name] =  Object.assign({...this.form}, {geografias: [...this.geografias], actividades: [...this.actividades]})
			vm._updateArce(group)
		},
		getAnosList(data) {
			let list = []
			data.forEach(element => {
				if(!list.includes(element.ano)) {
					list.push(element.ano)
				}
			});
			return list
		},
		showGeografias() {
			this.$set(this, 'geografiasForm', [...this.geografias.map(i => ({...i}))])
			this.showModal('#geografiasModalGroup')
		},

		showActividades() {
			this.$set(this, 'actividadesForm', [...this.actividades.map(i => ({...i}))])
			this.showModal('#actividadesModalGroup')
		},
		showClientes() {
			this.$set(this, 'principalesClientesForm', [...this.principalesClientes.map(i => ({...i}))])
			this.showModal('#clientesModalGroup')
		},
		addNew() {
			this.geografiasForm.push(
				{
					nombre: '',
					porcentaje: '',
					ano: '',
					query: ''
				}
			)
		},
		addNewActivity() {
			this.actividadesForm.push(
				{
					nombre: '',
					porcentaje: '',
					ano: '',
					query: ''
				}
			)
		},
		addNewClient() {
			this.principalesClientesForm.push(
				{
					nombre: '',
					participacion: '',
					query: ''
				}
			)
		},
		deleteItem(index, items) {
			items.splice(index, 1)
		},
		deleteActivityItem(index) {
			this.actividadesForm.splice(index, 1)
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},

		saveAllGoe() {
			this.$set(this, 'geografias',  [...this.geografiasForm.map(i => ({...i}))])
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'The record has been saved successfully'
			})
		},

		saveAllActivities() {
			this.$set(this, 'actividades', [...this.actividadesForm.map(i => ({...i}))])
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'The record has been saved successfully'
			})
		},
		saveAllClientes() {
			this.$set(this, 'principalesClientes', [...this.principalesClientesForm.map(i => ({...i}))])
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'The record has been saved successfully'
			})
		},
		closeModal() {
			this.hiddenSteps()
			this.fillForm()
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild'
		}),
		...mapActions({
			_updateArce: 'arceDetails/updateArce'
		})
	},
	mounted() {
		$('#formBusinnessriskGroupModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formBusinnessriskGroupModal').off('hide.bs.modal', this.closeModal)
	}
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="showModal('#formBusinnessriskGroupModal')">
				Editar
			</button>
		</div>
		<div class="">
			<div class="s-collapse__header " data-toggle="collapse" data-target="#abrg_1" aria-expanded="true" aria-controls="abrg_1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Información General</span></h6>
				</div>
			</div>
			<div id="abrg_1" class="collapse show" aria-labelledby="headingOne">
				<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#abrg_1_1" aria-expanded="true" aria-controls="abrg_1_1">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> ACTIVIDAD DEL CLIENTE</span></h6>
					</div>
				</div>
				<div id="abrg_1_1" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap">

						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Descripción de la actividad del Grupo</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="form.descripcionActividad">
							</label>
						</div>


						<div class="col-12 p-2">
							<label class="d-block">
								<p class="m-0"><span class="text-danger">*</span> ¿Opera en más de una geografía?</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="form.multipleGeografia">
							</label>
						</div>

						<div v-if="form.multipleGeografia == 'SI'" class="col-12 p-2">

							<h3 class="text-center">Desglose de ingresos país/Geografía (%)</h3>
							<div class="d-flex" v-if="showGeo.length">
								<div class="py-4 mx-auto col-8" >
									<table class="s-table w-100 text-center">
										<thead>
											<tr>
												<th>NOMBRE</th>
												<th>Porcentaje</th>
												<th>AÑO</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(sh, index) in showGeo" :key="`geos_${index}`">
												<td>{{sh.nombre}}</td>
												<td>{{sh.porcentaje}}%</td>
												<td>{{sh.ano}}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="col-2">
									<apexchart type="donut" :options="chart.chartOptions" :series="chart.series"></apexchart>
								</div>
							</div>
						</div>

						<!-- <div v-if="form.multipleGeografia == 'SI'" class="col-12 p-2">
							<button class="s-btn s-btn--outline s-btn--primary"  @click="showModal('#geografiasModalGroup')">Administrar Geografías</button>
						</div> -->
						
						<div class="col-12 p-2">
							<label class="d-block">
								<p class="m-0"><span class="text-danger">*</span> ¿Opera en más de una Actividad Económica?</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="form.multipleActividadEconomica">
							</label>
						</div>

						<div v-if="form.multipleActividadEconomica == 'SI'" class="col-4 p-2">
							<label class="col-12 p-0 m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios sobre las actividades</p>
								<textarea class="form-control w-100 d-block form-control--disabled" disabled rows="3" v-model="form.comentariosMultipleActividadEconomica"></textarea>
							</label>
						</div>
						<div v-if="form.multipleActividadEconomica == 'SI'" class="py-4 mx-auto col-8" >
							<table class="s-table w-100 text-center">
								<thead>
									<tr>
										<th>NOMBRE</th>
										<th v-for="(ano, index) in getAnosList(showActivities)" :key="`activity_${index}`">
											{{ano}}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(sh, index) in showActivities" :key="`activity-2_${index}`">
										<td>{{sh.nombre}}</td>
										<td v-for="(ano, index) in getAnosList(showActivities)" :key="`activity_${index}`">
											{{ ano == sh.ano ? sh.porcentaje : 0 }}%
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						

						
						
						
						<div class="col-4 p-2">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Vulnerabilidad al tipo de cambio</p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="form.vulnerabilidadTipoCambio">
							</label>
							<div v-if="form.vulnerabilidadTipoCambio == 'SI'" class="py-2">
								<label class="col-12 p-0 m-0">
									<p class="m-0"><span class="text-danger">*</span> Comentarios Vulnerabilidad al tipo de cambio</p>
									<textarea class="form-control w-100 d-block form-control--disabled" disabled  rows="3" v-model="form.comentariosVulnerabilidadTipoCambio"></textarea>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#abrg_1_2" aria-expanded="true" aria-controls="abrg_1_2">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> HECHOS RELAVANTES</span></h6>
					</div>
				</div>
				<div id="abrg_1_2" class="collapse show" aria-labelledby="headingOne">
					<div class="d-flex flex-wrap p-2">
						<div class="col-4 p-2">
							<label class="d-block">
								<p class="m-0"><span class="text-danger">*</span> ¿Hay algún hecho relevante? ¿Cuál es y como Impacta al grupo? (Alianzas / Capex significativo / Actividad M&A / Otros) </p>
								<input class="form-control form-control--disabled" disabled type="text" v-model="form.hechoRelevante">
							</label>
							<div v-if="form.hechoRelevante == 'SI'" class="py-2">
								<label class="col-12 p-0 m-0">
									<p class="m-0"><span class="text-danger">*</span> Comentarios Hecho Relevante</p>
									<textarea class="form-control w-100 d-block form-control--disabled" disabled rows="3" v-model="form.comentariosHechoRelevante"></textarea>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formBusinnessriskGroupModal" tabindex="-1" role="dialog" aria-labelledby="formBusinnessriskGroupModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="s-collapse__header " data-toggle="collapse" data-target="#abrg_m_1" aria-expanded="true" aria-controls="abrg_m_1">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Información General</span></h6>
						</div>
					</div>
					<div id="abrg_m_1" class="collapse show" aria-labelledby="headingOne">
						<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#abrg_m_1_1" aria-expanded="true" aria-controls="abrg_m_1_1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> ACTIVIDAD DEL CLIENTE</span></h6>
							</div>
						</div>
						<div id="abrg_m_1_1" class="collapse show" aria-labelledby="headingOne">
							<div class="d-flex flex-wrap">

								<div class="col-4 p-2">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Descripción de la actividad del Grupo</p>
										<textarea class="form-control" type="text" v-model="form.descripcionActividad">
										</textarea>
										<characters-available :max="500" :text="form.descripcionActividad"></characters-available>
									</label>
								</div>


								<div class="col-12 p-2">
									<label class="d-block">
										<p class="m-0"><span class="text-danger">*</span> ¿Opera en más de una geografía?</p>
										<div class="s-tf">
											<label class="s-tf__item">
												<input type="radio" name="abrg_omg" value="SI" v-model="form.multipleGeografia">
												<span class="s-tf__btn">SI</span>
											</label>
											<label class="s-tf__item" >
												<input type="radio" name="abrg_omg" value="NO" v-model="form.multipleGeografia">
												<span class="s-tf__btn">NO</span>
											</label>
										</div>
									</label>
								</div>

								<div v-if="form.multipleGeografia == 'SI'" class="col-12 p-2">

									<h3 class="text-center">Desglose de ingresos país/Geografía (%)</h3>
									<div class="d-flex" v-if="showGeo.length">
										<div class="py-4 mx-auto col-8" >
											<table class="s-table w-100 text-center">
												<thead>
													<tr>
														<th>NOMBRE</th>
														<th>Porcentaje</th>
														<th>AÑO</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(sh, index) in showGeo" :key="`geos_${index}`">
														<td>{{sh.nombre}}</td>
														<td>{{sh.porcentaje}}%</td>
														<td>{{sh.ano}}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div class="col-4">
											<apexchart type="donut" :options="chart.chartOptions" :series="chart.series"></apexchart>
										</div>
									</div>
								</div>

								<div v-if="form.multipleGeografia == 'SI'" class="col-12 p-2">
									<button class="s-btn s-btn--outline s-btn--primary"  @click="showGeografias">Administrar Geografías</button>
								</div>
								
								<div class="col-12 p-2">
									<label class="d-block">
										<p class="m-0"><span class="text-danger">*</span> ¿Opera en más de una Actividad Económica?</p>
										<div class="s-tf">
											<label class="s-tf__item">
												<input type="radio" name="abrg_omae" value="SI" v-model="form.multipleActividadEconomica">
												<span class="s-tf__btn">SI</span>
											</label>
											<label class="s-tf__item">
												<input type="radio" name="abrg_omae" value="NO" v-model="form.multipleActividadEconomica">
												<span class="s-tf__btn">NO</span>
											</label>
										</div>
									</label>
								</div>

								<div v-if="form.multipleActividadEconomica == 'SI'" class="col-4 p-2">
									<label class="col-12 p-0 m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios sobre las actividades</p>
										<textarea class="form-control w-100 d-block" rows="3" v-model="form.comentariosMultipleActividadEconomica"></textarea>
										<characters-available :max="1000" :text="form.comentariosMultipleActividadEconomica"></characters-available>

									</label>
								</div>
								<div v-if="form.multipleActividadEconomica == 'SI'" class="py-4 mx-auto col-8" >
									<table class="s-table w-100 text-center">
										<thead>
											<tr>
												<th>NOMBRE</th>
												<th v-for="(ano, index) in getAnosList(showActivities)" :key="`activity_${index}`">
													{{ano}}
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(sh, index) in showActivities" :key="`activity-2_${index}`">
												<td>{{sh.nombre}}</td>
												<td v-for="(ano, index) in getAnosList(showActivities)" :key="`activity_${index}`">
													{{ ano == sh.ano ? sh.porcentaje : 0 }}%
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div v-if="form.multipleActividadEconomica == 'SI'" class="col-12 p-2">
									<button class="s-btn s-btn--outline s-btn--primary"  @click="showActividades">Administrar Actividades</button>
								</div>

								
								
								
								<div class="col-4 p-2">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Vulnerabilidad al tipo de cambio</p>
										<div class="s-tf">
											<label class="s-tf__item">
												<input type="radio" name="abrg_vatc" value="SI" v-model="form.vulnerabilidadTipoCambio">
												<span class="s-tf__btn">SI</span>
											</label>
											<label class="s-tf__item">
												<input type="radio" name="abrg_vatc" value="NO" v-model="form.vulnerabilidadTipoCambio">
												<span class="s-tf__btn">NO</span>
											</label>
										</div>
									</label>
									<div v-if="form.vulnerabilidadTipoCambio == 'SI'" class="py-2">
										<label class="col-12 p-0 m-0">
											<p class="m-0"><span class="text-danger">*</span> Comentarios Vulnerabilidad al tipo de cambio</p>
											<textarea class="form-control w-100 d-block" rows="3" v-model="form.comentariosVulnerabilidadTipoCambio"></textarea>
											<characters-available :max="500" :text="form.comentariosVulnerabilidadTipoCambio"></characters-available>

										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="s-collapse__header bg-white  mt-2" data-toggle="collapse" data-target="#abrg_m_1_2" aria-expanded="true" aria-controls="abrg_m_1_2">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> HECHOS RELAVANTES</span></h6>
							</div>
						</div>
						<div id="abrg_m_1_2" class="collapse show" aria-labelledby="headingOne">
							<div class="d-flex flex-wrap p-2">
								<div class="col-4 p-2">
									<label class="d-block">
										<p class="m-0"><span class="text-danger">*</span> ¿Hay algún hecho relevante? ¿Cuál es y como Impacta al grupo? (Alianzas / Capex significativo / Actividad M&A / Otros) </p>
										<div class="s-tf">
											<label class="s-tf__item">
												<input type="radio" name="abrg_hr" value="SI" v-model="form.hechoRelevante">
												<span class="s-tf__btn">SI</span>
											</label>
											<label class="s-tf__item" >
												<input type="radio" name="abrg_hr" value="NO" v-model="form.hechoRelevante">
												<span class="s-tf__btn">NO</span>
											</label>
										</div>
									</label>
									<div v-if="form.hechoRelevante == 'SI'" class="py-2">
										<label class="col-12 p-0 m-0">
											<p class="m-0"><span class="text-danger">*</span> Comentarios Hecho Relevante</p>
											<textarea class="form-control w-100 d-block" rows="3" v-model="form.comentariosHechoRelevante"></textarea>
											<characters-available :max="150" :text="form.comentariosHechoRelevante"></characters-available>

										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>

		<div class="modal s-modal fade" id="geografiasModalGroup" tabindex="-1" role="dialog" aria-labelledby="geografiasModalGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title w-100 text-center" id="geografiasModalGroupLabel">Administrar Geografías</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="d-flex" v-for="(geo, index) in geografiasForm" :key="`geo_${index}`">
						<div class="col-4 mt-auto">
							<div class="position-relative">
								<div class="position-relative">
									<div class="s-input" data-v-step="cs1">
										<input v-if="!geo.nombre" class="form-control w-100" type="text" v-model="geo.query">
										<input v-else class="form-control w-100" disabled type="text" v-model="geo.nombre">
										<svg-icon class="mx-2" name="search" />
										<span class="m-0" @click="geo.nombre = ''" v-if="geo.nombre">
											<svg-icon class="mx-2 s-input__delete" name="close" />
										</span>
									</div>
									
								</div>
								<div v-if="geo.query" class="s-query-result">
									<div class="d-flex" @click="geo.nombre = geo.query, geo.query = ''">
										<button class="s-query-btn my-auto">
											<svg-icon name="add" />
										</button>
										<div class="ml-2 my-auto">
											<p class="small m-0">Create new arce...</p>
											<p class="m-0 small">{{geo.query}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4 mt-auto">
							<p class="m-0"> 
								Año
							</p>
							<input class="form-control w-100" type="text" v-model="geo.ano">
						</div>
						
						<div class="col-3 mt-auto">
							<p class="m-0"> 
								Porcentaje
							</p>
							<input class="form-control w-100" type="text" v-model="geo.porcentaje">
						</div>
						<div class="col-1 mt-auto">
							<button v-if="geografiasForm.length > 1" @click="deleteItem(index, geografiasForm)" class="s-btn s-btn--transparent px-0">
								<svg-icon name="delete" />
							</button>
						</div>
					</div>
					<div class="text-right mt-2">
						<button @click="addNew" class="s-query-btn my-auto mr-2 mt-2">
							<svg-icon name="add" />
						</button>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" @click="saveAllGoe" class="s-btn s-btn--primary">Save all</button>
				</div>
				</div>
			</div>
		</div>
		<div class="modal s-modal fade" id="actividadesModalGroup" tabindex="-1" role="dialog" aria-labelledby="actividadesModalGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title w-100 text-center" id="actividadesModalGroupLabel">Administrar Actividades</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="d-flex" v-for="(actividad, index) in actividadesForm" :key="`actividad_${index}`">
						<div class="col-4 mt-auto">
							<div class="position-relative">
								<div class="position-relative">
									<div class="s-input" data-v-step="cs1">
										<input v-if="!actividad.nombre" class="form-control w-100" type="text" v-model="actividad.query">
										<input v-else class="form-control w-100" disabled type="text" v-model="actividad.nombre">
										<svg-icon class="mx-2" name="search" />
										<span class="m-0" @click="actividad.nombre = ''" v-if="actividad.nombre">
											<svg-icon class="mx-2 s-input__delete" name="close" />
										</span>
									</div>
									
								</div>
								<div v-if="actividad.query" class="s-query-result">
									<div class="d-flex" @click="actividad.nombre = actividad.query, actividad.query = ''">
										<button class="s-query-btn my-auto">
											<svg-icon name="add" />
										</button>
										<div class="ml-2 my-auto">
											<p class="small m-0">Create new arce...</p>
											<p class="m-0 small">{{actividad.query}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4 mt-auto">
							<p class="m-0"> 
								Año
							</p>
							<input class="form-control w-100" type="text" v-model="actividad.ano">
						</div>
						
						<div class="col-3 mt-auto">
							<p class="m-0"> 
								Porcentaje
							</p>
							<input class="form-control w-100" type="text" v-model="actividad.porcentaje">
						</div>
						<div class="col-1 mt-auto">
							<button v-if="actividadesForm.length > 1" @click="deleteItem(index, actividadesForm)" class="s-btn s-btn--transparent px-0">
								<svg-icon name="delete" />
							</button>
						</div>
					</div>
					<div class="text-right mt-2">
						<button @click="addNewActivity" class="s-query-btn my-auto mr-2 mt-2">
							<svg-icon name="add" />
						</button>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" @click="saveAllActivities" class="s-btn s-btn--primary">Save all</button>
				</div>
				</div>
			</div>
		</div>

	</div>
</template>
